$(function () {
    $('.tabs-menu-nav__ref')
        .on('click', function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            var _this = $(this);
            var href = _this.attr('href');
            var $thisEl = $(this).closest('.tabs-menu-nav__lt__el');
            var $navLt = $(this).closest('.tabs-menu-nav__lt');
            var $nav = $(this).closest('.tabs-menu-nav');
            var $navIcon = $navLt.find('.tabs-menu-nav__lt__icon');

            if (!$nav.hasClass('nav-tabs')) {
                if (window.innerWidth >= 1227) {
                  $('.js-reset-all').removeClass('current');
                    if ($nav.hasClass('nav-ref')) {
                        $('html').addClass('relocation');
                        setTimeout(function () {
                            window.location.href = window.location.origin + href.toString();
                        }, 300);
                    }
                    //$thisEl.siblings().find('.tabs-menu-nav__ref').removeClass('current');
                    if($thisEl.find('.tabs-menu-nav__ref').hasClass('current') && $thisEl.find('.tabs-menu-nav__ref').hasClass('haschilds')){
                      $thisEl.find('.tabs-menu-nav__ref').removeClass('current');
                    }else{
                      $thisEl.find('.tabs-menu-nav__ref').toggleClass('current');
                    }

                    $navLt.removeClass('open');
                  $('.tabs-menu-nav__lt__el').each(function (i, el) {
                    if(!$(el).find('.tabs-menu-nav__ref').hasClass('haschilds')){
                      $(el).find('.tabs-menu-nav_dropDown__lt').removeClass('open');
                    }

                  });

                    var ddLt = $thisEl.find('.tabs-menu-nav_dropDown__lt');
                    if (ddLt.length > 0){
                      if (ddLt.hasClass('open')) {
                        ddLt.removeClass('open');
                        $thisEl.find('.tabs-menu-nav__ref').addClass('current');
                      } else {
                        if($thisEl.find('.tabs-menu-nav__ref').hasClass('current')){
                          ddLt.addClass('open');
                        }

                      }
                    }


                }
                if (window.innerWidth < 1227) {
                    if ($navLt.hasClass('open')) {
                        if ($nav.hasClass('nav-ref')) {
                            if (href !== undefined) {
                                window.location.href = window.location.origin + href.toString();
                            }
                            else {
                                $thisEl.siblings().find('.tabs-menu-nav__ref').removeClass('current');
                                $thisEl.find('.tabs-menu-nav__ref').addClass('current');
                                $navLt.removeClass('open');
                            }
                        }
                        else {
                            $thisEl.siblings().find('.tabs-menu-nav__ref').removeClass('current');
                            $thisEl.find('.tabs-menu-nav__ref').addClass('current');
                            $navLt.removeClass('open');
                        }
                    }
                    else {
                        $navLt.addClass('open');
                    }

                }
            }
            else if ($nav.hasClass('nav-tabs')) {
                var nav = $(this).closest('.nav-tabs');
                var $attr = nav.attr('tabs');
                var el = _this.closest('.tabs-menu-nav__lt__el');
                var box = '.tab-box[tabs="' + $attr + '"]';

                if (window.innerWidth >= 1227) {
                    if (!_this.hasClass('current')) {
                        el.siblings().find('.tabs-menu-nav__ref').removeClass('current');
                        _this.addClass('current');
                        $(box).eq(el.index())
                            .animate({opacity: 1}, 150,
                                function () {
                                    $(this).removeClass('tab-box_hidden')
                                })
                            .siblings(box)
                            .animate({opacity: 0}, 150, function () {
                                $(this).addClass('tab-box_hidden')
                            });
                    }
                }
                if (window.innerWidth < 1227) {
                    if ($navLt.hasClass('open')) {
                        if (!_this.hasClass('current')) {
                            el.siblings().find('.tabs-menu-nav__ref').removeClass('current');
                            _this.addClass('current');
                            $(box).eq(el.index())
                                .animate({opacity: 1}, 150,
                                    function () {
                                        $(this).removeClass('tab-box_hidden')
                                    })
                                .siblings(box)
                                .animate({opacity: 0}, 150, function () {
                                    $(this).addClass('tab-box_hidden')
                                });
                            $navLt.removeClass('open');
                        }
                        else {
                            $navLt.addClass('open');
                        }
                    }
                    else {
                        $navLt.addClass('open');
                    }
                }
            }
        });

    $('.tabs-menu-nav__lt__icon-wrap')
        .on('click', function (e) {
            var $this = $(this),
                _this = this;
            var $navLt = $this.closest('.tabs-menu-nav__lt');
            e.preventDefault();
            if (window.innerWidth < 1227) {
                $navLt.hasClass('open') ?
                    $navLt.removeClass('open') : $navLt.addClass('open');
            }
        });

    $('.tabs-menu-nav_dropDown__lt__ref')
        .on('click', function (e) {
            var $this = $(this),
                _this = this;
            var ddLt = $this.closest('.tabs-menu-nav_dropDown__lt');
            var inputCurrentCheckbox =  $this.children('.label').children('input');
            var inputCheckboxAll = ddLt.find('input[type="checkbox"]');
            e.preventDefault();
            if (window.innerWidth >= 1227) {
                if ($this.hasClass('current')) {
                    if ($this.hasClass('js-check-all') ) {
                      inputCheckboxAll.prop('checked',false);
                    }
                    $this.removeClass('current');
                    $this.find('span.icon').remove();
                    inputCurrentCheckbox.prop('checked',false);
                } else {
                    if ($this.hasClass('js-check-all') ) {
											ddLt.find('.tabs-menu-nav_dropDown__lt__ref').not('.js-check-all').removeClass('current');
											ddLt.find('.tabs-menu-nav_dropDown__lt__ref').not('.js-check-all').find('span.icon').remove();
											inputCheckboxAll.prop('checked',true);
                    } else if (ddLt.find('.tabs-menu-nav_dropDown__lt__ref.js-check-all').hasClass('current')) {
											ddLt.find('.tabs-menu-nav_dropDown__lt__ref.js-check-all').removeClass('current');
											ddLt.find('.tabs-menu-nav_dropDown__lt__ref.js-check-all').find('span.icon').remove();
											inputCheckboxAll.prop('checked',false);
                    }
                    $this.addClass('current');
                    $this.append('<span class="icon icon-close-mini"></span>');
                    inputCurrentCheckbox.prop('checked',true);
                }
                ddLt.removeClass('open');
            }
        });

    $(document)
        .on('click', function (event) {
            if ($(event.target).closest('.tabs-menu-nav__lt__icon-wrap').length) {
                return;
            }
            if ($(event.target).closest('.tabs-menu-nav__ref').length) {
                return;
            }
            if ($(event.target).closest('.tabs-menu-nav_dropDown__lt').hasClass('open')) {
                return;
            }

            $('.tabs-menu-nav__lt').removeClass('open');
            $('.tabs-menu-nav_dropDown__lt').removeClass('open');
        });

});
