(function ($) {
    "use strict";

    function ReSlider(el, option) {
        var _this = this;

        _this.el = el;
        _this.$el = $(el);
        _this.optionInit(option);
        _this.wrapperInit();
        _this.sliderInit();

        $(window).on('resize', function () {
            setTimeout(function () {
                _this.sliderInit();
            }, 300);
        });
    }

    $.extend(ReSlider.prototype, {
        optionInit: function (option) {
            var _this = this;

            _this.option = {
                all: false,
                allView: 1,
                phoneView: 1,
                tabletView: 2,
                desktopView: 3,
                nav: false,
                dots: false,
                phone: false,
                tablet: false,
                desktop: false,
                autoHeight: true
            };

            if (option !== undefined) {
                Object.keys(option).map(function (val, i) {
                    if (_this.option[val] === undefined) {
                        console.error("'" + val + "' - This option does not exist");
                    }
                    else {
                        _this.option[val] = option[val];
                    }
                });
            }

            if (_this.$el.data() !== undefined) {
                Object.keys(_this.$el.data()).map(function (val, i) {
                    if (_this.option[val] === undefined) {
                        console.error("'data-" + val + "'  - This option does not exist");
                    }
                    else {
                        _this.option[val] = _this.$el.data()[val];
                    }
                });
            }
        },
        wrapperInit: function () {
            var _this = this;
            _this.children = _this.$el.children().clone(true);
            _this.slides = _this.$el.children().clone(true);

            _this.$el.append('<div class="swiper-wrapper"></div>');
            _this.wrapper = _this.$el.find('.swiper-wrapper').remove();

            if (_this.option.dots === true) {
                _this.$el.append('<div class="swiper-pagination"></div>');
                _this.pagination = _this.$el.find('.swiper-pagination').remove();
            }
            if (_this.option.nav === true) {
                _this.$el.append('<div class="swiper-button-next"></div>');
                _this.slidesNavNext = _this.$el.find('.swiper-button-next').remove();
                _this.$el.append('<div class="swiper-button-prev"></div>');
                _this.slidesNavPrev = _this.$el.find('.swiper-button-prev').remove();
            }
            else if (_this.option.nav) {
                _this.slidesNavNext = '.' + _this.option.nav + ' .swiper-button-next';
                _this.slidesNavPrev = '.' + _this.option.nav + ' .swiper-button-prev';
            }

            $.map(_this.slides, function (sl, i) {
                $(sl).addClass('swiper-slide');
                _this.wrapper.append($(sl));
            });
        },
        sliderInit: function () {
            var _this = this;
            var curSwiper;
            if (_this.option.phone && window.innerWidth < 768) {
                if (_this.$el.mode !== 'reSlider_phone') {
                    setTimeout(function () {
                        _this.$el
                            .addClass('swiper-container')
                            .removeClass('reSlider_init')
                            .empty()
                            .append(_this.wrapper);
                        if (_this.option.dots) {
                            _this.$el.append(_this.pagination);
                        }
                        setTimeout(function () {
                            _this.$el.swiper({
                                pagination: '.swiper-pagination',
                                slidesPerView: 1,
                                paginationClickable: true,
                                spaceBetween: 0,
                                observer: true
                            });
                            _this.$el.mode = 'reSlider_phone';
                            _this.$el.attr('data-mode', 'reSlider_phone');
                            setTimeout(function () {
                                _this.$el.addClass('reSlider_init');
                            }, 0);
                        }, 0);
                    }, 0);
                }
            }
            else if (_this.option.tablet && window.innerWidth >= 768 && window.innerWidth <= 1024) {
                if (_this.$el.mode !== 'reSlider_tablet') {

                    setTimeout(function () {
                        _this.$el
                            .addClass('swiper-container')
                            .removeClass('reSlider_init')
                            .empty()
                            .append(_this.wrapper);
                        if (_this.option.dots === true) {
                            _this.$el.append(_this.pagination);
                        }
                        if (_this.nav === true) {
                            _this.$el.append(_this.slidesNavPrev);
                            _this.$el.append(_this.slidesNavNext);
                        }
                        _this.$el.swiper({
                            pagination: '.swiper-pagination',
                            slidesPerView: _this.option.tabletView,
                            paginationClickable: true,
                            spaceBetween: 0,
                            nextButton: _this.slidesNavNext,
                            prevButton: _this.slidesNavPrev,
                            observer: true,
                            breakpoints: {
                                1024: {
                                    slidesPerView: _this.option.desktopView,
                                    spaceBetween: 18
                                },
                                1023: {
                                    slidesPerView: _this.option.tabletView,
                                    spaceBetween: 18
                                }
                            }
                        });
                        _this.$el.mode = 'reSlider_tablet';
                        _this.$el.attr('data-mode', 'reSlider_tablet');
                        setTimeout(function () {
                            _this.$el.addClass('reSlider_init');
                        }, 0);
                    }, 0);
                }
            }
            else if (_this.option.desktop && window.innerWidth > 1024) {
                if (_this.$el.mode !== 'reSlider_desktop') {
                    setTimeout(function () {
                        _this.$el
                            .addClass('swiper-container')
                            .removeClass('reSlider_init')
                            .empty()
                            .append(_this.wrapper);
                        if (_this.option.dots === true) {
                            _this.$el.append(_this.pagination);
                        }
                        if (_this.nav === true) {
                            _this.$el.append(_this.slidesNavPrev);
                            _this.$el.append(_this.slidesNavNext);
                        }
                        _this.$el.swiper({
                            runCallbacksOnInit: true,
                            pagination: '.swiper-pagination',
                            slidesPerView: _this.desktopView,
                            paginationClickable: true,
                            spaceBetween: 0,
                            nextButton: _this.slidesNavNext,
                            prevButton: _this.slidesNavPrev,
                            observer: true
                        });
                        _this.$el.mode = 'reSlider_desktop';
                        _this.$el.attr('data-mode', 'reSlider_desktop');
                        setTimeout(function () {
                            _this.$el.addClass('reSlider_init');
                        }, 0);
                    }, 0);
                }
            }
            else if (_this.option.all) {
                if (_this.$el.mode !== 'reSlider_all') {
                    setTimeout(function () {
                        _this.$el
                            .addClass('swiper-container')
                            .removeClass('reSlider_init')
                            .empty()
                            .append(_this.wrapper);
                        if (_this.option.dots === true) {
                            _this.$el.append(_this.pagination);
                        }
                        if (_this.nav === true) {
                            _this.$el.append(_this.slidesNavPrev);
                            _this.$el.append(_this.slidesNavNext);
                        }
                        _this.$el.swiper({
                            runCallbacksOnInit: true,
                            pagination: _this.pagination,
                            slidesPerView: _this.allView,
                            paginationClickable: true,
                            spaceBetween: 0,
                            nextButton: _this.slidesNavNext,
                            prevButton: _this.slidesNavPrev,
                            observer: true,
                            autoHeight: true
                        });
                        _this.$el.mode = 'reSlider_all';
                        _this.$el.attr('data-mode', 'reSlider_all');
                        setTimeout(function () {
                            _this.$el.addClass('reSlider_init');
                        }, 0);
                    }, 0);
                }
                else {
                    setTimeout(function () {
                        _this.curSwiper = _this.$el[0].swiper;
                        if (_this.curSwiper != undefined) {
                            _this.curSwiper.onResize();
                        }
                    }, 100);
                }
            }
            else {
                if (_this.$el.mode !== 'reSlider_disabled') {
                    setTimeout(function () {
                        if (_this.$el.data('swiper') !== undefined) {
                            _this.$el.data('swiper').destroy();
                        }
                    }, 0);
                    setTimeout(function () {
                        _this.$el
                            .attr('class', 'reSlider')
                            .empty().append(_this.children);
                        _this.$el.mode = 'reSlider_disabled';
                        _this.$el.attr('data-mode', 'reSlider_disabled');
                        setTimeout(function () {
                            _this.$el.addClass('reSlider_init');
                        }, 0);
                    }, 0);
                }
            }
        }
    });

    $.fn.reSlider = function (option) {
        return this.each(function () {
            if (!$.data(this, 'reSlider')) {
                $.data(this, 'reSlider', new ReSlider(this, option));
            }
        });
    };

})(jQuery);
